import './CustomFooter.css';

function CustomFooter() {
    return (
      <div style={{paddingTop: '25px'}}>
        <p className="lead footer">&copy; Ghost Buster Bot {new Date().getFullYear()}</p>
        <p className="footer">Made with a positive intent in Virginia (please don't spam your coworkers)</p>
      </div>
    );
  }
  
  export default CustomFooter;
  