function AddToSlack() {
    return (
        <div className="centered-horizontally" style={{paddingTop: 25}}>
            <a href="https://slack.com/oauth/v2/authorize?client_id=712547842709.819000050934&scope=app_mentions:read,chat:write,commands,im:history,im:write">
                <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
            </a>
        </div>
    );
}

export default AddToSlack;
