// Library CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import About from './pages/About';
import Users from './pages/Users';
import Home from './pages/Home';
import CustomNavbar from './components/CustomNavbar';
import { Container } from 'react-bootstrap';
import CustomFooter from './components/CustomFooter';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Support from './pages/Support';
import Install from './pages/Install';
import Success from './pages/Success';

function App() {
  return (
    <Router className="allfonts">
      <CustomNavbar />
      <Container>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/users" element={<Users />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/install" element={<Install />} />
          <Route path="/success" element={<Success />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Container>
      <CustomFooter />
    </Router>
  );
}

export default App;
