import { Image } from "react-bootstrap";
import Ghost from '../assets/Ghost.svg';

function Support() {
    return (
        <>
            <div className="allfonts" style={{ paddingTop: 25 }}>
                <h1>Support or Inquiry?</h1>
                <div className="centered-horizontally" style={{ width: '100%' }}>
                    <div>
                        <div className="centered-horizontally">
                            <Image className="centered-horizontally" src={Ghost} style={{ maxWidth: '300px', maxHeight: '300px' }} />
                        </div>
                        <p style={{ textAlign: 'center' }}>Reach out to us at support@ghostbusterbot.io</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Support;
