import Image from 'react-bootstrap/Image';
import About1 from '../assets/About1.png';
import About2 from '../assets/About2.png';
import About3 from '../assets/About3.png';
import AddToSlack from '../components/AddToSlack';

function About() {
    return (
        <div className="centered-horizontally" style={{maxWidth: '100%'}}>
            <div className="allfonts" style={{paddingTop: 25, maxWidth: '100%'}}>
                <h1 style={{textAlign: 'center'}}>How's it work?</h1>
                <h3 style={{paddingTop: 25}}>Coworker not responding?</h3>
                <Image className="image" src={About1}/>
                <h3 style={{paddingTop: 25}}>Get their attention with a friendly ghost!</h3>
                <Image className="image" src={About2}/>
                <h3 style={{paddingTop: 25}}>The Ghoster gets a friendly message!</h3>
                <Image className="image" src={About3}/>
                <AddToSlack />
            </div>
        </div>
    )
}

export default About;