import './Home.css';
import Ghost from '../assets/Ghost.svg';
import { Image } from 'react-bootstrap';
import About from './About';
import AddToSlack from '../components/AddToSlack';

function Home() {
    return (
        <>
            <div className="centered allfonts">
                <div style={{ width: '100%' }}>
                    <div className="centered-horizontally">
                        <Image src={Ghost} style={{ maxWidth: '300px', maxHeight: '300px' }} />
                    </div>
                    <h1 style={{ textAlign: 'center' }}>Don't get Ghosted.</h1>
                    <p style={{ textAlign: 'center' }}>The friendly Slack App to bump, nudge, or poke your coworkers.</p>
                    <AddToSlack />
                </div>
            </div>
            <About />
        </>
    )
}

export default Home;
