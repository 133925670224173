import './Home.css';
import Ghost from '../assets/Ghost.svg';
import { Image } from 'react-bootstrap';
import About from './About';
import AddToSlack from '../components/AddToSlack';

function Install() {
    return (
        <>
            <div className="centered allfonts">
                <div style={{ width: '100%' }}>
                    <div className="centered-horizontally">
                        <Image src={Ghost} style={{ maxWidth: '300px', maxHeight: '300px' }} />
                    </div>
                    <AddToSlack />
                </div>
            </div>
            <About />
        </>
    )
}

export default Install;
